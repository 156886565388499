export const FirebaseConfig = {
	"projectId": "ridpo-delivery-de725",
	"appId": "1:532550764048:web:41f808a623cd826711ac95",
	"databaseURL": "https://ridpo-delivery-de725-default-rtdb.firebaseio.com",
	"storageBucket": "ridpo-delivery-de725.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyCvghhqw45xFi7_694QFp-s4obJNdYmvF0",
	"authDomain": "ridpo-delivery-de725.firebaseapp.com",
	"messagingSenderId": "532550764048",
	"measurementId": "G-EXLSTEJNC9"
};